// this file exports a svgs map to allow user get svg by name
// https://github.com/facebook/create-react-app/pull/3718
import ADD_EMPLOYEE from './svg/icon_add_employee.svg?react';
import ADMIN_LIST from './svg/icon_admin_list.svg?react';
import APP_CENTER from './svg/icon_app_center.svg?react';
import CHECK_IN from './svg/icon_check_in.svg?react';
import CHECKIN from './svg/icon_checkin.svg?react';
import CLAIM from './svg/icon_claim.svg?react';
import COMPANY from './svg/icon_company.svg?react';
import CONFIGURE from './svg/icon_configure.svg?react';
import DEPARTMENT from './svg/icon_department.svg?react';
import FILETYPE_EXCEL from './svg/icon_filetype_excel.svg?react';
import GOOGLE from './svg/icon_google.svg?react';
import IMPORT from './svg/icon_import.svg?react';
import INVITE from './svg/icon_invite.svg?react';
import LARGE_FILE from './svg/icon_large_file.svg?react';
import LEAVE_ATTENDANCE from './svg/icon_leave_attendance.svg?react';
import ROLE from './svg/icon_role.svg?react';
import TOAST_ERROR from './svg/icon_toast_error.svg?react';
import UPLOAD from './svg/icon_upload.svg?react';
import INFO from './svg/icon_toast_info.svg?react';
import SUCCESS from './svg/icon_toast_success.svg?react';
import WARNING from './svg/icon_toast_warning.svg?react';
import ICON_DOWNLOAD from './svg/icon_download.svg?react';
import ICON_PLUS from './svg/icon_plus.svg?react';

const svgs = {
  add_employee: ADD_EMPLOYEE,
  admin_list: ADMIN_LIST,
  app_center: APP_CENTER,
  check_in: CHECK_IN,
  checkin: CHECKIN,
  claim: CLAIM,
  company: COMPANY,
  configure: CONFIGURE,
  department: DEPARTMENT,
  filetype_excel: FILETYPE_EXCEL,
  google: GOOGLE,
  import: IMPORT,
  invite: INVITE,
  large_file: LARGE_FILE,
  leave_attendance: LEAVE_ATTENDANCE,
  role: ROLE,
  toast_error: TOAST_ERROR,
  upload: UPLOAD,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  icon_download: ICON_DOWNLOAD,
  plus: ICON_PLUS,
};

export default svgs;
