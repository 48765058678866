import CoralTag from '@seaweb/coral/components/Tag';
import Tooltip, { Placements } from '@seaweb/coral/components/Tooltip';
import InfoOIcon from '@seaweb/coral/icons/InfoO';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { THEME } from 'colorTheme';

const StyledTag = styled(CoralTag)`
  margin-left: 8px;
`;

const getTagColor = (tagType) => {
  switch (tagType) {
    case 'error':
      return THEME.colorError;
    case 'abnormal':
      return THEME.colorAbnormal;
    case 'success':
      return THEME.colorSuccess;
    case 'inactive':
      return THEME.colorTextSecondary;
    case 'warning':
      return THEME.colorWarning;
    default:
      return THEME.colorClick;
  }
};

export const Tag = forwardRef((props, ref) => {
  const { children, type, ...otherProps } = props;

  return (
    <StyledTag ref={ref} color={getTagColor(type)} type={type} {...otherProps}>
      {children}
    </StyledTag>
  );
});

Tag.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'error',
    'abnormal',
    'success',
    'inactive',
    'warning',
  ]),
};

export const TagWithTooltip = forwardRef((props, ref) => {
  const {
    children,
    placement = 'right',
    showIcon = true,
    tooltip,
    type,
    ...otherProps
  } = props;

  const withIcon = tooltip && showIcon;

  return (
    <Tooltip title={tooltip} placement={placement}>
      <StyledTag ref={ref} color={getTagColor(type)} {...otherProps}>
        {children}
        {withIcon && <InfoOIcon size={16} style={{ marginLeft: 2 }} />}
      </StyledTag>
    </Tooltip>
  );
});

TagWithTooltip.propTypes = {
  children: PropTypes.node,
  showIcon: PropTypes.bool,
  tooltip: PropTypes.string,
  placement: Placements,
};
